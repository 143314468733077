import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import { TopSection } from '../components/topsection';
import ArticleCard from '../components/articles/card';
import Wrapper from '../components/layout/wrapper';
import SEO from '../components/seo'

const ArticleTemplate = ({ data }) => (
  <Layout>
    <SEO title="Aktuelt" />
    <TopSection title='Aktuelt' color='peach' />
    <Wrapper center>
      <section id='all-articles'>
        {data.post.nodes.map((item) => (
          <ArticleCard
            title={item.title}
            to={`/aktuelt/${item.slug}`}
            image={item.featuredImage}
            category={item.categories}
            id={item.id}
            excerpt={item.excerpt}
          />
        ))}
      </section>
    </Wrapper>
  </Layout>
);

export default ArticleTemplate;

export const ARTICLES = graphql`
  query articles {
    post: allWpArticles(filter: { title: { ne: "Test-artikkel" } }) {
      nodes {
        slug
        title
        id
        categories {
          nodes {
            name
          }
        }
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(cropFocus: CENTER, toFormat: WEBP, webpQuality: 10) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
