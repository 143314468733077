import React from 'react';

import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Button from '../../layout/buttons';
import Category from '../categories';
import './articleCard.scss';

const ArticleCard = ({ title, id, image, category, excerpt, to, noBtn }) => {
  const img = {
    src: image?.node.localFile.childImageSharp.fluid,
    alt: image?.node.altText ? image.node.altText : '',
  };

  return (
    <Link
      className='article-card'
      to={to}
      itemScope
      itemProp='http://schema.org/Article'
      key={id}
    >
      <article>
        <Img className='article-featured-img' fluid={img.src} alt={img.alt} />
        <div className='categories'>
          {category.nodes.map((item) => (
            <Category name={item.name} />
          ))}
        </div>
        <h2 itemProp='headline'>{title}</h2>
        <div
          className='excerpt'
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></div>
        {noBtn !== true ? <Button to={to}>Les mer</Button> : null}
      </article>
    </Link>
  );
};

export default ArticleCard;
